import React from "react";
import styles from './Navigation.module.scss';
import homeIcon from '../../assets/icons/home.svg';
import homeIconBlue from '../../assets/icons/homeBlue.svg';
import walletIcon from '../../assets/icons/wallet.svg';
import walletIconBlue from '../../assets/icons/walletBlue.svg';
import proIcon from '../../assets/icons/pro.svg';
import proIconBlue from '../../assets/icons/proBlue.svg';
import managerIcon from '../../assets/icons/manager.svg';
import managerIconBlue from '../../assets/icons/managerBlue.svg';
import historyIcon from '../../assets/icons/history.svg';
import historyIconBlue from '../../assets/icons/historyBlue.svg';
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {useSelector} from "react-redux";
import {getText} from "../../store/selectors";

const Navigation = ({theme}) => {
    const location = useLocation().pathname;
    const {global} = useSelector(getText);
    const hide = location.substring(0, 5) === '/acco'
        || location === '/wallet/withdraw/'
        || location === '/wallet/deposit/'
        || location === '/deposit/'
        || location === '/withdraw/';

    const navigations = [
        {icon: homeIcon, iconBlue: homeIconBlue, name: global.main, location: '/'},
        {icon: walletIcon, iconBlue: walletIconBlue, name: global.wallet, location: '/wallet/'},
        {icon: proIcon, iconBlue: proIconBlue, name: global.pro, location: '/pro/spot/'},
        // {icon: managerIcon, iconBlue: managerIconBlue, name: global.manager, location: '/manager/'},
        {icon: historyIcon, iconBlue: historyIconBlue, name: global.history, location: '/history/orders/'}
    ]

    return (
        <div className={hide ? `${styles.hide} nav` : `${styles.navigationWrapper} nav`}>
            {navigations && (
                navigations.map((item, index) => (
                    <Link
                        key={index}
                        to={item.location}
                        className={location.substring(0, 5) === item.location.substring(0, 5) ? `${styles.navigationItemActive} linkActive` : styles.navigationItem}
                    >
                        <div className={styles.navigationItem__icon}>
                            <img
                                src={theme === 'light' && location.substring(0, 5) !== item.location.substring(0, 5) ? item.iconBlue : item.icon}
                                alt=""/>
                        </div>
                        <p className={theme === 'light' && location.substring(0, 5) === item.location.substring(0, 5) ? styles.activeText : ''}>
                            {item.name}
                        </p>
                    </Link>
                ))
            )}
        </div>
    )
}

export default Navigation;