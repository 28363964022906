import React from "react";
import styles from './TransactionListCVV.module.scss';


const TransactionListCVV = ({transactionsList}) => {
    return (
        <ul className={styles.transactionListCVV}>
            {transactionsList && (
                transactionsList.map((item, index) => (
                    <li key={index} className='switch'>
                        <div className={styles.transactionListCVV__header}>
                            <img src={item.icon} alt=""/>
                            <p>{item.name}</p>
                        </div>
                        <span className='circle' />
                        <input type="radio" name='ordersCVV' />
                    </li>
                ))
            )}
        </ul>
    )
}

export default TransactionListCVV;