import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAcToken, getCurrentToken, getRefToken, getText, getTheme} from "../../store/selectors";
import styles from './Withdraw.module.scss';
import {PagesHeader} from "../Common/PagesHeader/PagesHeader";
import TokenBalance from "./TokenBalance/TokenBalance";
import ScanAddAddress from "./ScanAddAddress/ScanAddAddress";
import info from "../../assets/icons/notificationOrange.svg";
import WithdrawValue from "./WithdrawValue/WithdrawValue";
import WithdrawSum from "./WithdrawSum/WithdrawSum";
import PreviewWithdraw from "./PreviewWithdraw/PreviewWithdraw";
import Verify from "./Verify/Verify";
import WithdrawProcessing from "./WithdrawProcessing/WithdrawProcessing";
import {getGeneralAddress, refreshAccessToken} from "../../api/api";
import {setAcToken} from "../../store/account-slice";
import {useAppDispatch} from "../../hooks/redux";
import {Preloader} from "../Common/Preloader/Preloader";
import {useTelegram} from "../../hooks/useTelegram";


const Withdraw = () => {
    const theme = useSelector(getTheme);
    const token = useSelector(getCurrentToken);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();
    const {wallet} = useSelector(getText);
    const [withdrawValue, setWithdrawValue] = useState('0.00');
    const [address, setAddress] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [startProcessing, setStartProcessing] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    // const [qrMessage, setQrMessage] = useState('');
    const [networkInfo, setNetworkInfo] = useState(null);
    const [addressFrom, setAddressFrom] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const {telegram} = useTelegram();

    telegram.onEvent('qrTextReceived', (response) => {
        setAddress(response.data);
        telegram.closeScanQrPopup();
    })

    const openScanner = () => {
        telegram.showScanQrPopup((res) => console.log(res));
    }

    useEffect(() => {
        if (token && token.token) {
            const networkObj = token.token.networks.filter(item => item.networkType === token.network);
            setNetworkInfo(...networkObj);
        }
        if (addressFrom === null && token.network && accessToken) {
            getGeneralAddress(token.network, accessToken).then(result => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            getGeneralAddress(token.network, r.access_token).then(result => {
                                if (result && result.address) {
                                    setAddressFrom(result.address)
                                }
                            })
                        }
                    });
                } else {
                    getGeneralAddress(token.network, accessToken).then(result => {
                        if (result && result.address) {
                            setAddressFrom(result.address)
                        }
                    })
                }

            });
        }
    }, [token])

    // useEffect(() => {
    //     const config = {fps: 10, qrbox: {width: 200, height: 200}};
    //     const html5QrCode = new Html5Qrcode('qrCodeContainer');
    //
    //     const qrScannerStop = () => {
    //         if (html5QrCode && html5QrCode.isScanning) {
    //             html5QrCode.stop().then(() => console.log("Scanner stop")).catch(() => console.log("Scanner error"));
    //         }
    //     }
    //
    //     const qrCodeSuccess = (decodedText) => {
    //         // setQrMessage(decodedText);
    //         setAddress(decodedText);
    //         setIsEnabled(false)
    //     }
    //
    //     if (isEnabled) {
    //         html5QrCode.start({facingMode: "environment"}, config, qrCodeSuccess);
    //         // setQrMessage("")
    //     } else {
    //         qrScannerStop();
    //     }
    //
    //     return (() => {
    //         qrScannerStop();
    //     })
    // }, [isEnabled])

    return (
        <div className={`${styles.withdrawWallet} bgMain`}>
            <PagesHeader headerText={wallet.withdraw} menu/>
            <TokenBalance token={token}/>
            <ScanAddAddress address={address} setAddress={setAddress} setIsEnabled={setIsEnabled}
                            isEnabled={isEnabled} openScanner={openScanner}/>
            <div className={`${styles.info} event`}>
                <img src={info} alt=""/>
                <p>{wallet.withdrawWalletInfoPart1} {token.token.name} {wallet.to} ({token.network}) {wallet.withdrawWalletInfoPart2}</p>
            </div>
            <WithdrawValue withdrawValue={withdrawValue} setWithdrawValue={setWithdrawValue} text={wallet.amountIn}
                           token={token.token.name} balance={token.token.finalBalance} networkInfo={networkInfo}/>
            <WithdrawSum text={wallet} token={token.token.name} balance={token.token.finalBalance}
                         networkInfo={networkInfo}/>
            <button className={`${styles.mainBtn} bottomBtn`}
                    disabled={address === 'Insert address' || address === '' || withdrawValue === '0.00'}
                    onClick={() => setShowPreview(true)}>
                <span>{wallet.previewWithdrawal}</span>
            </button>
            <PreviewWithdraw token={token} address={address} withdrawValue={withdrawValue} showPreview={showPreview}
                             networkInfo={networkInfo} setShowPreview={setShowPreview} setShowVerify={setShowVerify}
                             setStartProcessing={setStartProcessing} addressFrom={addressFrom} setIsLoading={setIsLoading}/>
            {showPreview && (
                <button className={styles.closer} onClick={() => {
                    setShowPreview(false);
                }}/>
            )}
            {token && networkInfo && token.token && (
                <Verify text={wallet} showVerify={showVerify} setShowVerify={setShowVerify} token={token}
                        withdrawValue={withdrawValue} setStartProcessing={setStartProcessing} addressFrom={addressFrom}
                        address={address} networkInfo={networkInfo}/>
            )}
            {token && networkInfo && token.token && (
                <WithdrawProcessing text={wallet} startProcessing={startProcessing}
                                    setStartProcessing={setStartProcessing} networkInfo={networkInfo}
                                    token={token} withdrawValue={withdrawValue} address={address}
                                    setShowVerify={setShowVerify} setShowPreview={setShowPreview}
                                    setWithdrawValue={setWithdrawValue}/>
            )}

            {/*<div className={isEnabled ? styles.visible : styles.hide} id="qrCodeContainer"/>*/}
            {/*{isEnabled && (*/}
            {/*    <button className="qr-closer" onClick={() => setIsEnabled(false)}>*/}
            {/*        <img src={theme === 'dark' ? close : closeBlue} alt=""/>*/}
            {/*    </button>*/}
            {/*)}*/}
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default Withdraw;