import React from "react";
import styles from './BaseList.module.scss';
import usdtIcon from '../../../assets/currencyIcons/usdt.svg';
import btcIcon from '../../../assets/currencyIcons/usdt.svg';
import ethIcon from '../../../assets/currencyIcons/usdt.svg';
import ltcIcon from '../../../assets/currencyIcons/usdt.svg';
import bnbIcon from '../../../assets/currencyIcons/usdt.svg';
import trxIcon from '../../../assets/currencyIcons/usdt.svg';
import {getPictureSrc} from "../../../store/selectors";


const BaseList = ({list, setSelectItem}) => {
    const pictureSrc = getPictureSrc;

    return (
        <ul className={styles.managerList}>
            {list && (
                list.map((item, index) => (
                    <li key={index} className={styles.item} onClick={() => setSelectItem(item)}>
                        <div className={styles.item__name}>
                            <img src={`${pictureSrc}${item.icon}`} alt=""/>
                            <p>{item.name}</p>
                        </div>
                        <div className={styles.item__value}>
                            <p>{item.finalBalance} {item.name}</p>
                            <span>{item.warrants} USD</span>
                        </div>
                    </li>
                ))
            )}
        </ul>
    )
}

export default BaseList;