import React, {useState} from "react";
import HistoryPeriod from "./HistoryPeriod/HistoryPeriod";
import HistoryCVVFilter from "./HistoryCVVFilter/HistoryCVVFilter";
import CVVOrdersList from "./CVVOrdersList/CVVOrdersList";
import HistoryCVVBtn from "../HistoryCVVBtn/HistoryCVVBtn";
import {useSelector} from "react-redux";
import {getOrdersHistoryCVV} from "../../../../store/selectors";


const OrdersContentCVV = ({account}) => {
    const [period, setPeriod] = useState('30');
    const ordersCVV = useSelector(getOrdersHistoryCVV);

    return (
        <>
            <HistoryPeriod period={period} setPeriod={setPeriod} />
            <HistoryCVVFilter />
            <CVVOrdersList ordersCVV={ordersCVV} />
            <HistoryCVVBtn text={account.get} />
        </>
    )
}

export default OrdersContentCVV;