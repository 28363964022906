import React from "react";

export const getLanguages = async (value) => {
    const english = {
        main: {
            totalAmount: 'Total amount in wallets',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            exchange: 'Exchange',
        },
        wallet: {
            title: 'Wallets',
            deposit: 'Deposit',
            withdraw: 'Withdraw',
            exchange: 'Exchange',
            all: 'All',
            hideZero: 'Hide zero balance',
            commercial: 'Commercial',
            warrants: 'In warrants',
            finalBalanas: 'Final balance',
            addAddress: 'Add address',
            selectNetwork: 'Select network',
            selectWithdraw: 'Select asset to withdraw',
            selectDeposit: 'Select asset to deposit',
            requestAddress: 'Request new address',
            sendTetherPart1: 'Only for',
            sendTetherPart2: '. Other assets will be lost',
            changeWallet: 'Change wallet',
            insertAddress: 'Insert address',
            generateAddress: 'Generate new address',
            addWallet: 'Add a wallet',
            createWallet: 'Create a wallet',
            nameWallet: 'Name the wallet',
            nameForWallet: 'Name for the wallet',
            newWalletAddress: 'New wallet address',
            save: 'Save',
            amountIn: 'Amount in',
            fee: 'Fee',
            minimum: 'Minimum',
            maximum: 'Maximum withdrawal',
            previewWithdrawal: 'Preview withdrawal',
            withdrawWalletInfoPart1: 'Only for',
            to: 'to',
            withdrawWalletInfoPart2: '. Other assets will be lost',
            managerDeposit: 'Manager deposit addresses',
            authenticatorApp: 'Authenticator app',
            support: 'Contact Support',
            codeText: 'Enter the Sign-in 2FA code from your authenticator app',
            time: 'Time',
            status: 'Status',
            wallet: 'Wallet',
            commission: 'Commission',
            viewExplorer: 'View on block explorer',
        },
        pro: {
            headerTitle: 'Exchange',
            title: 'Swap',
            warrantExecuted: 'Warrant is executed',
            day: 'Day',
            status: 'Status',
            commission: 'Commission',
            today: 'Today',
            noFavorites: 'No favorite  currencies yet',
            spot: 'Spot',
            swap: 'Swap',
            market: 'Market',
            limit: 'Limit',
            limitPrice: 'Limit price',
            marketPrice: 'Market price',
            buy: 'Buy',
            sell: 'Sell',
            quanity: 'Quanity',
            total: 'Total',
            availableBalance: 'Available balance',
            openOrders: 'Open orders',
            noOpenOrders: 'No open orders',
            exchange: 'Exchange',
            selectToken: 'Select Token',
        },
        manager: {
            title: 'Manager',
        },
        history: {
            title: 'History',
            filter: 'Filter',
            chooseCurrency: 'Choose currency',
            warrantExecuted: 'Warrant is executed',
            time: 'Time',
            status: 'Status',
            side: 'Side',
            type: 'Type',
            amount: 'Amount',
            ordersPrice: 'Orders price',
            commission: 'Commission',
            orders: 'Orders',
            transactions: 'Transactions',
            all: 'All',
            limit: 'Limit',
            market: 'Market',
            oneDay: '24h',
            sevenDay: 'Last 7d',
            send: 'Receive',
            receive: 'Exchange',
            done: 'Done',
            getCvv: 'Get CVV',
        },
        account: {
            title: 'Account',
            safety: 'Safety',
            verification: 'Two factor verification',
            KycVerification: 'KYC verification',
            KycVerificationText: 'To get full access to the bots functions, please complete your identity verification process.',
            aml: 'AML',
            information: 'Information',
            support: 'Support',
            language: 'Language',
            history: 'History CVV',
            downloadText: 'Download and install the Google app Authenticator',
            downloadLink: 'Download the app',
            enter: 'Enter',
            sendAuthCode: 'Google Authenticator Verification Code',
            code6: '6-digit code',
            historyCVV: 'History CVV',
            type: 'Type',
            period: 'Period',
            currencyPair: 'Currency pair',
            get: 'Get',
            currency: 'Currency',
            telegramBot: 'Protects your account and transactions, works through Telegram Bot @S WalletBot',
            googleAuth: 'Protects your account  and transactions',
            googleAuthOff: 'Disabling two-factor authentication (2FA) exposes your account to a high risk  of unauthorized access. If you decide  to disable (2FA), we strongly recommend that you enable it again as soon as possible.  If unusual activity is detected after  the shutdown (2FA) , you will not be able  to withdraw funds or sell assets on the P2P platform for 24 hours.',
            getContinue: 'I get it, continue',
            english: 'English',
            russian: 'Russian',
        },
        global: {
            main: 'Main',
            wallet: 'Wallet',
            pro: 'Pro',
            manager: 'Manager',
            history: 'History',
            cancel: 'Cancel',
        },
        aml: {
            title: 'AML verification',
            amlPayment: 'Payment for inspection',
            amlAddress: 'Address verification ',
            token: 'Token',
            commission: 'The amount of the check payment:',
            pay: 'Pay',
            enterAddress: 'Enter the token of the address you want to check',
            network: 'Network',
            send: 'Send for inspection',
            address: 'Address',
            riskLow: 'Low risk address',
            riskMedium: 'medium risk address',
            riskHigh: 'High risk address',
            toHome: 'To home page',
            other: 'Other',
            paymentManagement: 'Payment Management',
            wallet: 'Wallet',
            exchange: 'Exchange',
            liquidityPools: 'Liquidity Pools',
            exchangeHigh: 'Exchange | High Risk',
            highP2P: 'High-Risk P2P Exchange',
            gambling: 'Gambling',
            sanctions: 'Sanctions',
            stolenCoins: 'Stolen Coins',
            transactions: 'Transactions:',
            modeChecked: 'mode. Checked:',
            lowRisk: 'Low risk',
            mediumRisk: 'Medium risk',
            highRisk: 'High risk',
        }
    }

    const russian = {
        main: {
            totalAmount: 'Общая сумма в кошельках',
            deposit: 'Депозит',
            withdraw: 'Отозвать',
            exchange: 'Обмен',
        },
        wallet: {
            title: 'Кошелек',
            deposit: 'Депозит',
            withdraw: 'Отозвать',
            exchange: 'Обмен',
            all: 'Все',
            hideZero: 'Скрыть нулевой баланс',
            commercial: 'Коммерческий',
            warrants: 'Гарантии',
            finalBalanas: 'Финальные баланы',
            addAddress: 'Добавить адрес',
            selectNetwork: 'Выбор сети',
            selectWithdraw: 'Выберите актив для вывода',
            selectDeposit: 'Выберите актив для депозита',
            requestAddress: 'Запросить новый адрес',
            sendTetherPart1: 'Только для',
            sendTetherPart2: '. Другие активы будут потеряны',
            changeWallet: 'Изменить кошелек',
            insertAddress: 'Вставить адрес',
            generateAddress: 'Создать новый адрес',
            addWallet: 'Добавить кошелек',
            createWallet: 'Создать кошелек',
            nameWallet: 'Назовите кошелек',
            nameForWallet: 'Имя для кошелька',
            newWalletAddress: 'Новый адрес кошелька',
            save: 'Сохранить',
            amountIn: 'Сумма в',
            fee: 'Платеж',
            minimum: 'Минимум',
            maximum: 'Максимальный вывод',
            previewWithdrawal: 'Предварительный вывод средств',
            withdrawWalletInfoPart1: 'Только для',
            to: '',
            withdrawWalletInfoPart2: '. Остальные активы будут потеряны',
            managerDeposit: 'Адреса вкладов управляющего',
            authenticatorApp: 'Аутентификация',
            support: 'Контактная поддержка',
            codeText: 'Введите код 2FA для входа в систему из приложения для аутентификации.',
            time: 'Время',
            status: 'Статус',
            wallet: 'Кошелек',
            commission: 'Комиссия',
            viewExplorer: 'Посмотреть в обозревателе блоков',
        },
        pro: {
            headerTitle: 'Обмен',
            Exchange: 'Менять',
            title: 'Обмен',
            warrantExecuted: 'Ордер исполнен',
            day: 'День',
            status: 'Статус',
            commission: 'Комиссия',
            today: 'Сегодня',
            noFavorites: 'Любимых валют пока нет',
            spot: 'Место',
            swap: 'Менять',
            market: 'Рынок',
            limit: 'Лимит',
            limitPrice: 'Лимитная цена',
            marketPrice: 'Рыночная цена',
            buy: 'Купить',
            sell: 'Продать',
            quanity: 'Количество',
            total: 'Сумма',
            availableBalance: 'Доступные средства',
            openOrders: 'Открытые ордера',
            noOpenOrders: 'Нет открытых ордеров',
            exchange: 'Обменять',
            selectToken: 'Выберите токен',
        },
        manager: {
            title: 'Менеджер',
        },
        history: {
            title: 'История',
            filter: 'Фильтр',
            chooseCurrency: 'Выберите валюту',
            warrantExecuted: 'Ордер исполнен',
            time: 'Время',
            status: 'Статус',
            side: 'Сторона',
            type: 'Тип',
            amount: 'Количество',
            ordersPrice: 'Стоимость заказов',
            commission: 'Комиссия',
            orders: 'Заказы',
            transactions: 'Транзакции',
            all: 'Все',
            limit: 'Лимит',
            market: 'Рынок',
            oneDay: '24ч',
            sevenDay: 'Последние 7д',
            send: 'Получено',
            receive: 'Отправлено',
            done: 'Применить',
            getCvv: 'Получить CVV',
        },
        account: {
            title: 'Пользователь',
            safety: 'Безопасность',
            verification: 'Двухфакторная проверка',
            KycVerification: 'KYC проверка',
            KycVerificationText: 'Чтобы получить полный доступ к функциям ботов, пройдите процедуру проверки личности.',
            aml: 'AML',
            information: 'Информация',
            support: 'Поддержка',
            language: 'Язык',
            history: 'Исторя CVV',
            downloadText: 'Загрузите и установите приложение Google Authenticator.',
            downloadLink: 'Скачать приложение',
            enter: 'Войти',
            sendAuthCode: 'Код проверки подлинности Google',
            code6: '6-значный код',
            historyCVV: 'История CVV',
            type: 'Тип',
            period: 'Период',
            currencyPair: 'Валютная пара',
            get: 'Получить',
            currency: 'Валюта',
            telegramBot: 'Защищает вашу учетную запись и транзакции, работает через Telegram Bot @S WalletBot',
            googleAuth: 'Защищает вашу учетную запись и транзакции',
            googleAuthOff: 'Отключение двухфакторной аутентификации (2FA) подвергает вашу учетную запись высокому риску несанкционированного доступа. Если вы решите отключить (2FA), мы настоятельно рекомендуем вам включить его снова как можно скорее. Если после отключения (2FA) будет обнаружена необычная активность, вы не сможете вывести средства или продать активы на P2P-платформе в течение 24 часов.',
            getContinue: 'Я понял, продолжить',
            english: 'Английский',
            russian: 'Русский',
        },
        global: {
            main: 'Главная',
            wallet: 'Кошелек',
            pro: 'Про',
            manager: 'Менеджер',
            history: 'История',
            cancel: 'Отмена',
        },
        aml: {
            title: 'AML Проверка',
            amlPayment: 'Оплата за осмотр',
            amlAddress: 'Проверка адреса',
            token: 'Токен',
            commission: 'Сумма чека платежа:',
            pay: 'Платить',
            enterAddress: 'Введите токен адреса, который вы хотите проверить',
            network: 'Сеть',
            send: 'Отправить на проверку',
            address: 'Адрес',
            riskLow: 'Адрес с низким уровнем риска',
            riskMedium: 'Адрес среднего риска',
            riskHigh: 'Адрес высокого риска',
            toHome: 'На главную страницу',
            other: 'Другой',
            paymentManagement: 'Управление оплатой',
            wallet: 'Кошелек',
            exchange: 'Обмен',
            liquidityPools: 'Пулы ликвидности',
            exchangeHigh: 'Обмен | Высокий риск',
            highP2P: 'P2P-биржа высокого риска',
            gambling: 'Азартные игры',
            sanctions: 'Санкции',
            stolenCoins: 'Украденные монеты',
            transactions: 'Транзакции:',
            modeChecked: 'режим. Проверено:',
            lowRisk: 'Низкий риск',
            mediumRisk: 'Средний риск',
            highRisk: 'Высокий риск',
        }
    };

    switch (value) {
        case 'english' : return english;
        case 'russian' : return russian;
    }
}