import React, {useEffect, useState} from "react";
import styles from './HistoryPeriod.module.scss';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

const HistoryPeriod = ({period, setPeriod}) => {
    const [startDate, setStartDate] = useState('');
    const [finishDate, setFinishDate] = useState('');
    const [detail, setDetail] = useState(null);

    const handleMask = (event) => {
        setDetail(event.detail);
    };
    const modify = (input) => {
        return undefined;
    };

    const setDate = (value) => {
        setPeriod(`${value}`)
        const nowDate = new Date();
        setFinishDate(dayjs(`${nowDate.getFullYear()}-${nowDate.getMonth()+1}-${nowDate.getDate()}`));
        let date = new Date();
        date.setDate(date.getDate() - value);
        const newDate = date.toISOString();
        setStartDate(dayjs(`${newDate.substring(4,0)}/${newDate.slice(5,7)}/${newDate.slice(8,10)}`));
    }

    useEffect(() => {
        if (startDate === '' && finishDate === '') {
            setDate(30);
        }
    })

    return (
        <>
            <div className={styles.historyPeriod}>
                <div className={`${styles.historyPeriod__button} choose`}>
                    <input checked={period === '0'} type="radio" name='historyPeriod'
                           onChange={() => setDate(0)} />
                    <p className='bgBlock'>24h</p>
                </div>
                <div className={`${styles.historyPeriod__button} choose`}>
                    <input checked={period === '7'} type="radio" name='historyPeriod'
                           onChange={() => setDate(7)} />
                    <p className='bgBlock'>Last 7d</p>
                </div>
                <div className={`${styles.historyPeriod__button} choose`}>
                    <input checked={period === '30'} type="radio" name='historyPeriod'
                           onChange={() => setDate(30)} />
                    <p className='bgBlock'>Last 30d</p>
                </div>
            </div>
            <div className={styles.dateFields}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateField', 'DateField']}>
                        <DateField
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            format="YYYY/MM/DD"
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateField', 'DateField']}>
                        <DateField
                            value={finishDate}
                            onChange={(newValue) => setFinishDate(newValue)}
                            format="YYYY/MM/DD"
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </div>
        </>
    )
}

export default HistoryPeriod;