import {createSlice} from "@reduxjs/toolkit";
import btcIcon from "../assets/currencyIcons/btc.svg";
import usdtIcon from "../assets/currencyIcons/usdt.svg";
import bnbIcon from "../assets/currencyIcons/bnb.svg";
import ethIcon from "../assets/currencyIcons/eth.svg";

const initialState = {
    historyOrders: [
        {
            type: 'market',
            isAccepted: true,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '0.9141 USD',
            price: '55,691.68 USDT',
        },
        {
            type: 'limit',
            isAccepted: true,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '1.5 BTC',
            price: '42000,00',
        },
        {
            type: 'market',
            isAccepted: false,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '0.9141 USD',
            price: '55,691.68 USDT',
        },
        {
            type: 'market',
            isAccepted: true,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '0.9141 USD',
            price: '55,691.68 USDT',
        },
        {
            type: 'market',
            isAccepted: true,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '0.9141 USD',
            price: '55,691.68 USDT',
        },
        {
            type: 'market',
            isAccepted: true,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '0.9141 USD',
            price: '55,691.68 USDT',
        },
        {
            type: 'limit',
            isAccepted: true,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Sell',
            amount: '1.2 BTC',
            price: '43000,00',
        },
        {
            type: 'market',
            isAccepted: false,
            name: 'BTC/USDT',
            data: '24-01-18',
            time: '20:29:05',
            side: 'Buy',
            amount: '0.9141 USD',
            price: '55,691.68 USDT',
        },
    ],
    historyTransactions: [],
    ordersHistoryCVV: [],
    transactionsHistoryCVV: []
};

export const historySlice = createSlice({
    name: 'historyPage',
    initialState,
    reducers: {
    }
})

export default historySlice.reducer;
