import React, {useEffect, useState} from "react";
import styles from './Wallet.module.scss'
import {PagesHeader} from "../Common/PagesHeader/PagesHeader";
import WalletOperations from "./WalletOperations/WalletOperations";
import WalletFilter from "./WalletFilter/WalletFilter";
import WalletSearch from "./WalletSearch/WalletSearch";
import WalletList from "./WalletList/WalletList";
import {useSelector} from "react-redux";
import {getText, getWallets} from "../../store/selectors";
import {Preloader} from "../Common/Preloader/Preloader";

const Wallet = () => {
    const [hideZero, setHideZero] = useState(false);
    const {wallet} = useSelector(getText);
    const wallets = useSelector(getWallets);
    const [isLoading, setIsLoading] = useState(true);
    const [walletList, setWalletList] = useState([]);
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (wallets && searchValue === '') {
            setWalletList(wallets)
        } else if (wallets) {
            const result = wallets
                .filter(prof => {
                    return prof.name.toLowerCase().includes(searchValue.toLowerCase());
                })
                .sort((a, b) => {
                    if(a.name.toLowerCase().indexOf(searchValue.toLowerCase()) > b.name.toLowerCase().indexOf(searchValue.toLowerCase())) {
                        return 1;
                    } else if (a.name.toLowerCase().indexOf(searchValue.toLowerCase()) < b.name.toLowerCase().indexOf(searchValue.toLowerCase())) {
                        return -1;
                    } else {
                        if(a.name > b.name)
                            return 1;
                        else
                            return -1;
                    }
                });
            setWalletList(result);
        }
    }, [wallets, searchValue])

    return (
        <div className={`${styles.wallet} bgMain`}>
            <PagesHeader headerText={wallet.title} menu/>
            <WalletOperations deposit={wallet.deposit} withdraw={wallet.withdraw} exchange={wallet.exchange}/>
            <WalletFilter text={wallet.all}/>
            {/*<WalletSearch text={wallet.hideZero} hideZero={hideZero} setHideZero={setHideZero} searchValue={searchValue}*/}
            {/*              setSearchValue={setSearchValue}/>*/}
            <WalletList text={wallet} hideZero={hideZero} setIsLoading={setIsLoading} wallets={walletList}/>
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default Wallet;