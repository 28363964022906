import React from "react";
import styles from './Steps.module.scss';

const Steps = ({step, text}) => {
    return (
        <div className={`${styles.steps} bgDark`}>
            <p>{text}</p>
            <div className={styles.steps__process}>
                <span className={`${styles.stepActive} linkActive`}>
                    1
                </span>
                <span className={`${styles.decor} decoration`} />
                <span className={step > 1 ? `${styles.stepActive} linkActive` : `${styles.step} bgLittleBtn`}>
                    2
                </span>
                <span className={`${styles.decor} decoration`} />
                <span className={step > 2 ? `${styles.stepActive} linkActive` : `${styles.step} bgLittleBtn`}>
                    3
                </span>
            </div>
        </div>
    )
}

export default Steps;