import React, {useState} from "react";
import styles from './Header.module.scss';
import accountIcon from '../../../assets/icons/userSettings.svg';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getTheme, getUserId} from "../../../store/selectors";
import {useAppDispatch} from "../../../hooks/redux";
import {setAcToken, setStateTheme} from "../../../store/account-slice";
import {refreshAccessToken, setTheme} from "../../../api/api";
import settingsIcon from '../../../assets/icons/settingsIcon.svg';
import {useTelegram} from "../../../hooks/useTelegram";


const Header = ({setIsLoading}) => {
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const theme = useSelector(getTheme);
    const dispatch = useAppDispatch();
    const refreshToken = useSelector(getRefToken);

    // const [isEnabled, setIsEnabled] = useState(false);
    const [qrMessage, setQrMessage] = useState('');

    const {telegram} = useTelegram();

    telegram.onEvent('qrTextReceived', (response) => {
        setQrMessage(response.data);
        telegram.closeScanQrPopup();
    })

    const openScanner = () => {
        telegram.showScanQrPopup((res) => console.log(res));
    }

    // const setCloser = () => {
    //     setTimeout(() => {
    //         setIsEnabled(true);
    //     }, 250)
    // }

    // useEffect(() => {
    //     const config = {fps: 10, qrbox: {width: 200, height: 200}};
    //     const html5QrCode = new Html5Qrcode('qrCodeContainer');
    //
    //     if (!html5QrCode) {
    //         setIsEnabled(false);
    //     }
    //
    //     const qrScannerStop = () => {
    //         if (html5QrCode && html5QrCode.isScanning) {
    //             html5QrCode.stop().then(() => console.log("Scanner stop")).catch(() => setIsEnabled(false))
    //         }
    //     }
    //
    //     const qrCodeSuccess = (decodedText) => {
    //         setQrMessage(decodedText);
    //         setIsEnabled(false);
    //     }
    //
    //
    //     if (isEnabled) {
    //         html5QrCode.start({facingMode: "environment"}, config, qrCodeSuccess);
    //         setQrMessage("");
    //         setTimeout(() => {
    //             if (!html5QrCode.isScanning) {
    //                     setIsEnabled(false);
    //                 }
    //         },250);
    //     } else {
    //         qrScannerStop();
    //     }
    //
    //     return(() => {
    //         qrScannerStop();
    //     })
    // }, [isEnabled])

    const onChangeTheme = () => {
        setIsLoading(true)
        if (theme && theme === 'dark' && telegramId && accessToken) {
            setTheme(telegramId, 'light', accessToken).then((result) => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            setTheme(telegramId, 'light', r.access_token).then((result) => {
                                if (result && result.current_theme) {
                                    dispatch(setStateTheme(result.current_theme));
                                    setIsLoading(false);
                                }
                            })
                        }
                    });
                }
                if (result && result.current_theme) {
                    dispatch(setStateTheme(result.current_theme));
                    setIsLoading(false);
                }
            });
        }
        if (theme && theme === 'light' && telegramId && accessToken) {
            setTheme(telegramId, 'dark', accessToken).then((result) => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            setTheme(telegramId, 'dark', r.access_token).then((result) => {
                                if (result && result.current_theme) {
                                    dispatch(setStateTheme(result.current_theme));
                                    setIsLoading(false);
                                }
                            })
                        }
                    });
                }
                if (result && result.current_theme) {
                    dispatch(setStateTheme(result.current_theme));
                    setIsLoading(false);
                }
            });
        }
    }

    return (
        <div className={styles.headerWrapper}>
            <div className={styles.account}>
                <Link to='/account/' className='circleBtn circleBtnAcc'>
                    <img src={accountIcon} alt=""/>
                </Link>
                {telegramId && (
                    <p>ID {telegramId}</p>
                )}
            </div>
            <div className={styles.buttons}>
                {/*<button className={`circleBtn`} onClick={() => onChangeTheme()}>*/}
                {/*    <img src={theme === 'dark' ? darkIcon : lightIcon} alt=""/>*/}
                {/*</button>*/}
                <Link to='/account/' className='circleBtn circleBtnAcc'>
                    <img src={settingsIcon} alt=""/>
                </Link>
            </div>
            {/*<div className={isEnabled ? styles.visible : styles.hide} id="qrCodeContainer"/>*/}
            {/*{isEnabled && (*/}
            {/*    <button className="qr-closer" onClick={() => setIsEnabled(false)}>*/}
            {/*        <img src={theme === 'dark' ? close : closeBlue} alt=""/>*/}
            {/*    </button>*/}
            {/*)}*/}

        </div>
    )
}
export default Header;