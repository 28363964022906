import React from "react";
import styles from './TransactionTypeCVV.module.scss';

const TransactionTypeCVV = () => {
    return (
        <div className={styles.transactionTypeCVV}>
            <div className={styles.transactionTypeCVV__list}>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input type="radio" name='transactionsCVVFilter' />
                    <span className='bgBlock'>All</span>
                </div>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input type="radio" name='transactionsCVVFilter' />
                    <span className='bgBlock'>Send</span>
                </div>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input type="radio" name='transactionsCVVFilter' />
                    <span className='bgBlock'>Receive</span>
                </div>
                <div className={`${styles.transactionTypeCVV__choose} choose`}>
                    <input type="radio" name='transactionsCVVFilter' />
                    <span className='bgBlock'>Exchange</span>
                </div>
            </div>
        </div>
    )
}

export default TransactionTypeCVV;