import React from "react";
import styles from './EventPopup.module.scss'
import {ClosePopup} from "../../Common/ClosePopup/ClosePopup";
import {getPictureSrc} from "../../../store/selectors";

const EventPopup = ({isShow, event, onClose}) => {
    const pictureSrc = getPictureSrc;
    return (
        <div className={isShow ? `${styles.popupActive} bgSecond` : `${styles.popup} bgSecond`}>
            <ClosePopup setShowPopup={onClose}/>
            {event && (
                <h2>{event.isDeposit ? `Depositing` : `Withdrawal`}</h2>
            )}
            {event && (
                <div className={`${styles.popup__header} bgDark`}>
                    <div className={styles.popup__headerLeft}>
                        <img src={`${pictureSrc}${event.icon}`} alt=""/>
                        <div>
                            <p>{event.token}</p>
                            <span>USDT</span>
                        </div>
                    </div>
                    <div className={styles.popup__headerRight}>
                        <p>{event.deposit}</p>
                        <span>{event.deposit_usdt}$</span>
                    </div>
                </div>
            )}
            {event && (
                <div className={`${styles.popup__info} bgBlock`}>
                    <div>
                        <p>Aderess</p>
                        <p>{event.address.substring(0, 7)}.....{event.address.substring(event.address.length - 8, event.address.length)}</p>
                    </div>
                    <div>
                        <p>Blockchain</p>
                        <p>{event.blockchain}</p>
                    </div>
                    <div>
                        <p>Network</p>
                        <p>{event.network}</p>
                    </div>
                    <div>
                        <p>Depositing</p>
                        <p>{event.deposit_usdt}</p>
                    </div>
                </div>
            )}
        </div>
    )
}

export default EventPopup;