import React, {useEffect, useState} from "react";
import styles from './WalletsManager.module.scss';
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import {useSelector} from "react-redux";
import {getText, getCurrentNetwork, getCurrentCurrency, getNetworkWallets} from "../../../store/selectors";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {CopyToClipboard} from "react-copy-to-clipboard";
import copyIcon from '../../../assets/icons/copyGrey.svg';
import penIcon from '../../../assets/icons/penGrey.svg';
import WalletSettings from "../../Common/WalletSettings/WalletSettings";
import CreateWalletPopup from "../../Common/CreateWalletPopup/CreateWalletPopup";
import {setEventPopup} from "../../../store/main-slice";
import {Preloader} from "../../Common/Preloader/Preloader";

const WalletsManager = () => {
    const {wallet} = useSelector(getText);
    const network = useSelector(getCurrentNetwork);
    const token = useSelector(getCurrentCurrency);
    const networks = useAppSelector(getNetworkWallets);
    const [networkList, setNetworkList] = useState([]);
    const [walletSettings, setWalletSettings] = useState(null);
    const [showCreateWallet, setShowCreateWallet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const onCopy = (e) => {
        e.stopPropagation();
        dispatch(setEventPopup({
            success: true,
            text: 'Copied!'
        }))
    }

    useEffect(() => {
        if (network) {
            setNetworkList(networks[network])
        }
    },[network, networks])

    return (
        <div className={`${styles.walletsManager} bgMain`}>
            <PagesHeader headerText={wallet.managerDeposit} />
            <ul className={styles.walletsManager__list}>
                {networkList && token && networkList.length && (
                    networkList.map((item, index) => (
                        <li className={`${styles.wallet} bgBlock`} key={index}>
                            <div className={styles.wallet__header}>
                                {item.name && (
                                    <p className={styles.wallet__headerName}>{item.name}</p>
                                )}
                                <p className={`${styles.wallet__headerNetwork} bgListItem`}>
                                    <span>{token}</span><span>{item.network}
                                </span></p>
                            </div>
                            <span className={styles.wallet__index}>Index {index + 1}</span>
                            <div className={styles.wallet__footer}>
                                <p>{`${item.address.substring(0, 7)}...${item.address.substring(item.address.length-8, item.address.length)}`}</p>
                                <div className={styles.wallet__footerActions}>
                                    <CopyToClipboard text={item.address}>
                                        <button className='bgLittleBtn' type='button' onClick={(e) => onCopy(e)}>
                                            <img src={copyIcon} alt="copy"/>
                                        </button>
                                    </CopyToClipboard>
                                    <button onClick={() => setWalletSettings(item)}>
                                        <img src={penIcon} alt=""/>
                                    </button>
                                </div>
                            </div>
                        </li>
                    ))
                )}
            </ul>
            <div className={`${styles.action} action`}>
                <button onClick={() => setShowCreateWallet(true)}>
                    <span>{wallet.requestAddress}</span>
                </button>
            </div>
            <WalletSettings walletSettings={walletSettings} setWalletSettings={setWalletSettings} setIsLoading={setIsLoading} />
            {network && showCreateWallet && (
                <CreateWalletPopup
                    network={network}
                    text={wallet}
                    setShowCreateWallet={setShowCreateWallet}
                />
            )}
            {isLoading && (
                <div className='loadingWrapper'>
                    <Preloader/>
                </div>
            )}
        </div>
    )
}

export default WalletsManager;