import React from "react";
import backgroundDark from "../../../../assets/icons/bgDarkSecond.png";
import backgroundLight from "../../../../assets/icons/bgLightSecond.png";
import styles from './AmlResult.module.scss';
import {useSelector} from "react-redux";
import {getTheme} from "../../../../store/selectors";
import AmlResultInfo from "./AmlResultInfo";
import {useNavigate} from "react-router";

const AmlResult = ({result, setResult, text}) => {
    const theme = useSelector(getTheme);
    const navigate = useNavigate();

    return (
        <div className={result ? styles.amlResultActive : styles.amlResult}
             style={theme === 'dark' ? {backgroundImage: `url(${backgroundDark})`} : {backgroundImage: `url(${backgroundLight})`}}>
            <p className={styles.amlResult__title}>AML verification</p>
            <div className={`${styles.amlResult__content} bgMain`}>
                {result && (
                    <>
                        <div className={`${styles.content__header} bgDark`}>
                            <span className={styles.content__title}>{result.network} {text.address}</span>
                            <span className={styles.content__address}>{result.address}</span>
                            <p className={styles.content__result}>
                                <span>{result.riskscore < 40 ? `${text.riskLow}`
                                    : result.riskscore < 60 && result.riskscore >= 40 ? `${text.riskMedium}`
                                        : `${text.riskHigh}`}</span>
                                <span>{result.riskscore}%</span>
                            </p>
                        </div>
                        <AmlResultInfo result={result} text={text} />
                        <div className={styles.action}>
                            <button className={'action'} onClick={() => {
                                setResult(null);
                                setTimeout(() => {
                                    navigate('/');
                                },10)
                            }}>
                                <span>{text.toHome}</span>
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AmlResult;