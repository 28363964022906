import React from "react";
import styles from "./HistoryType.module.scss";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";


const HistoryType = () => {
    const location = useLocation().pathname;

    return (
        <>
            <div className={styles.historyType}>
                <div className={`${styles.historyType__button}`}>
                    <Link className={location === '/account/history/orders/'
                        ? `${styles.linkActive} ${styles.active} linkActive bgBlock`
                        : `${styles.link} bgBlock`}
                          to='/account/history/orders/'>Orders
                    </Link>
                </div>
                <div className={styles.historyType__button}>
                    <Link className={location === '/account/history/transactions/'
                        ? `${styles.linkActive} ${styles.active} linkActive bgBlock`
                        : `${styles.link} bgBlock`}
                          to='/account/history/transactions/'>Transactions
                    </Link>
                </div>
            </div>
        </>
    )
}

export default HistoryType;