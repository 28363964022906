import React from "react";
import styles from './AmlStep.module.scss';

const AmlStep = ({step, text}) => {
    return (
        <div className={`${styles.amlStep} bgDark`}>
            <p>{step === 1 ? text.amlPayment : text.amlAddress}</p>
            <div className={styles.amlStep__process}>
                <span className={step === 1 ? `${styles.stepActive} linkActive` : `${styles.step} bgLittleBtn`}>
                    1
                </span>
                <span className={`${styles.decor} decoration`} />
                <span className={step === 2 ? `${styles.stepActive} linkActive` : `${styles.step} bgLittleBtn`}>
                    2
                </span>
            </div>
        </div>
    )
}

export default AmlStep;