import React, {useState} from "react";
import styles from './Changer.module.scss';
import upIcon from "../../../../assets/icons/upIcon.svg";
import ExchangeDropdown from "./ExchangeDropdown/ExchangeDropdown";
import {getPictureSrc} from "../../../../store/selectors";


const Changer = ({value, setValue, currentCurrency, operation, currency, setCurrency, setSecondValue, price, giveValue, setIsLoading, text}) => {
    const [showDropdown, setShowDropdown] = useState('');
    const pictureSrc = getPictureSrc;

    // console.log(price)

    const handleChange = (value) => {
        const newValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        if (price && price.price && operation === "Give") {
            setValue(newValue);
            if (newValue !== '' && value && price.name === price.ticker.substring(0, price.name.length)) {
                const fixedPrice = newValue / price.price;
                fixedPrice.toString().length > 6 ? setSecondValue(fixedPrice.toFixed(6)) : setSecondValue(fixedPrice)
            } else {
                const fixedPrice = newValue * price.price;
                fixedPrice.toString().length > 6 ? setSecondValue(fixedPrice.toFixed(6)) : setSecondValue(fixedPrice);
            }

        } else if (price && price.price && operation === "Get") {
            setValue(newValue);
            if (newValue !== '' && value && price.name === price.ticker.substring(0, price.name.length)) {
                const fixedPrice = newValue * price.price;
                fixedPrice.toString().length > 6 ? setSecondValue(fixedPrice.toFixed(6)) : setSecondValue(fixedPrice)
            } else {
                const fixedPrice = newValue / price.price;
                fixedPrice.toString().length > 6 ? setSecondValue(fixedPrice.toFixed(6)) : setSecondValue(fixedPrice)
            }
        }
    }

    const onStart = () => {
        if (value < 0.00000001) {
            setValue('')
        }
    }

    const onEnd = () => {
        if (value === '') {
            setValue(0.00)
        }
    }

    return (
        <div className={`${styles.change} bgBlock`}>
            <div className={styles.change__header}>
                <p>{operation}</p>
                <p>Spot balance <span>{currentCurrency ? currentCurrency.name : ''}</span></p>
            </div>
            <div className={styles.change__balance}>
                <div className={styles.inputField}>
                    {operation && operation === "Get" && (
                        <span>≈</span>
                    )}
                    <input type='number' inputmode="decimal" value={value} readOnly={price === null} maxLength='12'
                           onFocus={() => onStart()}
                           onBlur={() => onEnd()}
                           onChange={(e) => handleChange(e.target.value)}/>
                </div>


                <div className={styles.customSelect} onClick={() => setShowDropdown('give')}>
                    {currentCurrency && (
                        <img src={currentCurrency ? `${pictureSrc}${currentCurrency.icon}` : ''} alt=""/>
                    )}

                    <p>{currentCurrency ? currentCurrency.name : text.selectToken}</p>
                    <img className={styles.customSelect__up} src={upIcon} alt=""/>
                    <ExchangeDropdown
                        operation={operation}
                        currentCurrency={currentCurrency}
                        showDropdown={showDropdown}
                        value={'give'} currency={currency}
                        setCurrency={setCurrency}
                        setIsLoading={setIsLoading}
                        setShowDropdown={setShowDropdown}/>
                </div>
            </div>
            {currentCurrency && operation === 'Give' && (
                <div className={styles.change__footer}>
                    <p>{currentCurrency.finalBalance} <span>{currentCurrency.name}</span> available</p>
                </div>
            )}
            {showDropdown !== '' && (
                <button className={styles.closer} onClick={() => setShowDropdown('')}/>
            )}
        </div>
    )
}

export default Changer;