import React, {useEffect, useState} from "react";
import styles from './WalletSettings.module.scss';
import {ClosePopup} from "../ClosePopup/ClosePopup";
import {getWalletsInfo, refreshAccessToken, setTwoFactorCode, updateWallet} from "../../../api/api";
import {useSelector} from "react-redux";
import {getAcToken, getRefToken, getUserId} from "../../../store/selectors";
import {setNetworkWallets, setUserWallets} from "../../../store/wallet-slice";
import {useAppDispatch} from "../../../hooks/redux";
import copyIcon from "../../../assets/icons/copyGrey.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {setEventPopup} from "../../../store/main-slice";
import {setAcToken, setTwoFactorAuth} from "../../../store/account-slice";
import {Preloader} from "../Preloader/Preloader";

const WalletSettings = ({walletSettings, setWalletSettings, setIsLoading}) => {
    const [name, setName] = useState('');
    const telegramId = useSelector(getUserId);
    const accessToken = useSelector(getAcToken);
    const refreshToken = useSelector(getRefToken);
    const dispatch = useAppDispatch();


    useEffect(() => {
        if (walletSettings) {
            setName(walletSettings.name)
        } else {
            setName('')
        }
    }, [walletSettings])

    const onClose = () => {
        setWalletSettings(null)
        setIsLoading(false);
    }

    const onSave = () => {
        setIsLoading(true)
        if (walletSettings) {
            updateWallet(walletSettings.network, walletSettings.address, name, accessToken).then(result => {
                if (result && result.detail && result.detail === 'access_token expired') {
                    refreshAccessToken(refreshToken).then(r => {
                        if (r && r.access_token) {
                            dispatch(setAcToken(r.access_token));
                            updateWallet(walletSettings.network, walletSettings.address, name, r.access_token).then(result => {
                                if (telegramId && accessToken && result && result.name) {
                                    getWalletsInfo(telegramId, r.access_token).then(response => {
                                        if (response && response.tokens) {
                                            dispatch(setUserWallets(response.tokens))
                                            dispatch(setNetworkWallets(response.wallets));
                                            onClose();
                                        }
                                    })
                                }
                            })
                        }
                    });
                }
                if (telegramId && accessToken && result && result.name) {
                    getWalletsInfo(telegramId, accessToken).then(response => {
                        if (response && response.tokens) {
                            dispatch(setUserWallets(response.tokens))
                            dispatch(setNetworkWallets(response.wallets));
                            onClose();
                        }
                    })
                } else {
                    setIsLoading(false);
                }
            });
        }

    }

    return (
        <div
            className={walletSettings ? `${styles.walletSettingsActive} bgSecond` : `${styles.walletSettings} bgSecond`}>
            <ClosePopup setShowPopup={onClose}/>
            <h2>Setting wallet</h2>
            {walletSettings && (
                <div className={styles.boxWrapper}>
                    <div className={`${styles.box} bgBlock`}>
                        <span className={styles.box__label}>Name the address</span>
                        <input className={styles.box__value} maxLength='10' value={name} onChange={(e) => setName(e.target.value)}
                               type="text"/>
                    </div>
                    <div className={styles.addressWrapper}>
                        <div className={`${styles.box} bgBlock`}>
                            <span className={styles.box__label}>Wallet address</span>
                            <span className={styles.box__value}>
                                {`${walletSettings.address.substring(0, 7)}.....${walletSettings.address.substring(walletSettings.address.length - 7, walletSettings.address.length)}`}
                            </span>
                        </div>
                    </div>

                </div>
            )}
            <div className={styles.box__actions}>
                {walletSettings && (
                    <button onClick={() => onSave()} disabled={name === '' || name === walletSettings.name}
                            className='action'>
                        <span>Save</span>
                    </button>
                )}
            </div>

        </div>
    )
}

export default WalletSettings;