import { combineReducers, configureStore } from '@reduxjs/toolkit';

import walletSlice from './wallet-slice';
import proSlice from './pro-slice';
import mainSlice from './main-slice';
import languageSlice from './language-slice';
import accountSlice from './account-slice';
import historySlice from './history-slice';


const rootReducers = combineReducers({
    wallet: walletSlice,
    pro: proSlice,
    main: mainSlice,
    language: languageSlice,
    account: accountSlice,
    history: historySlice,
});

export const store = configureStore({
    reducer: rootReducers
});
