import React, {useState} from "react";
import styles from './DepositWallet.module.scss';
import {PagesHeader} from "../../Common/PagesHeader/PagesHeader";
import {SearchFull} from "../../Common/SearchFull/SearchFull";
import BaseList from "../../Common/BaseList/BaseList";
import {useSelector} from "react-redux";
import {getText, getWallets} from "../../../store/selectors";
import WithdrawDepositPopup from "../WithdrawDepositPopup/WithdrawDepositPopup";

const DepositWallet = () => {
    const {wallet} = useSelector(getText);
    const balances = useSelector(getWallets);
    const [value, setValue] = useState('');
    const [selectItem, setSelectItem] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const networkPopup = (item) => {
        setSelectItem(item);
        setShowPopup(true);
    }

    return (
        <div className={`${styles.depositWallet} bgMain`}>
            <PagesHeader headerText={wallet.deposit} menu />
            <SearchFull placeholder={'Search assets'} value={value} setValue={setValue} />
            {/*<p className={styles.depositWallet__subtitle}>{wallet.selectDeposit}</p>*/}
            <div className={styles.list}>
                <BaseList setSelectItem={networkPopup} list={balances} />
            </div>
            <WithdrawDepositPopup showPopup={showPopup} item={selectItem} setShowPopup={setShowPopup} />
        </div>
    )
}

export default DepositWallet;