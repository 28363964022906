import React from "react";
import close from "../../../assets/icons/close.svg";
import closeBlue from "../../../assets/icons/closeBlue.svg";
import {useSelector} from "react-redux";
import {getTheme} from "../../../store/selectors";
import styles from './ClosePopup.module.scss';

export const ClosePopup = ({setShowPopup}) => {
    const theme = useSelector(getTheme);

    return (
        <div className={styles.closePopup}>
            <button onClick={() => setShowPopup(false)}>
                <img src={theme === 'dark' ? close : closeBlue} alt=""/>
            </button>
        </div>
    )
}


