import React, {useEffect, useState} from "react";
import styles from './Exchange.module.scss';
import {useAppSelector} from "../../../../hooks/redux";
import {getText} from "../../../../store/selectors";

const Exchange = ({value, setValue, coursePrice, currentTicker, setIsError, operation, total, setTotal, process, pro}) => {
    let FixProcess = 0;

    useEffect(() => {
        if (FixProcess !== process) {
            FixProcess = process;

            if (value === 0 && coursePrice === 0) {
                setTotal(0)
            } else {
                const fixedValue = value * coursePrice;
                fixedValue.toString().length > 6 ? setTotal(fixedValue.toFixed(6)) : setTotal(fixedValue);
            }
        }


    }, [value, coursePrice])

    useEffect(() => {
        if (FixProcess !== process) {
            FixProcess = process;

            if (total === 0 && coursePrice === 0) {
                setValue(0);
            } else {
                const fixedValue = total / coursePrice;
                fixedValue.toString().length > 6 ? setValue(fixedValue.toFixed(6)) : setValue(fixedValue)
            }
        }
    }, [total])

    const handleChange = (value) => {
        const newValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        if (operation === 'Sell') {
            if (currentTicker.balance === 0) {
                setIsError(true);
            } else if (newValue > currentTicker.balance) {
                const fixedValue = currentTicker.balance * coursePrice;
                setValue(currentTicker.balance);
                fixedValue.toString().length > 6 ? setTotal(fixedValue.toFixed(6)) : setTotal(fixedValue)
                setIsError(false);
            } else {
                setIsError(false);
                setValue(newValue);
            }
        } else if (operation === 'Buy') {
            if (total > currentTicker.balance2) {
                setTotal(currentTicker.balance2);
                setIsError(false);
            } else {
                setIsError(false);

                if (newValue * coursePrice > currentTicker.balance2) {
                    const fixedValue = currentTicker.balance2 / coursePrice;
                    setTotal(currentTicker.balance2);
                    fixedValue.toString().length > 6 ? setValue(fixedValue.toFixed(6)) : setValue(fixedValue);

                } else {
                    setValue(newValue);
                    const fixedValue = newValue * coursePrice;
                    fixedValue.toString().length > 6 ? setTotal(fixedValue.toFixed(6)) : setTotal(fixedValue);
                }
            }
        }
    }

    const onTotalChange = (value) => {
        const newValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        if (newValue > currentTicker.balance2) {
            if (operation === 'Buy') {
                const fixedValue = currentTicker.balance2 / coursePrice;
                setTotal(currentTicker.balance2);
                fixedValue.toString().length > 6 ? setValue(fixedValue.toFixed(6)) : setValue(fixedValue)

            } else {
                const fixedValue = currentTicker.balance * coursePrice;
                setValue(currentTicker.balance);
                fixedValue.toString().length > 6 ? setTotal(fixedValue.toFixed(6)) : setTotal(fixedValue)
            }

        } else if (newValue !== 0 && coursePrice !== 0) {
            const fixedValue = newValue / coursePrice;
            fixedValue.toString().length > 6 ? setValue(fixedValue.toFixed(6)) : setValue(fixedValue)
            setTotal(newValue);
        } else {
            setValue(0);
        }
    }

    const onStart = (value, setValue) => {
        if (value < '0.0000001') {
            setValue('')
        }
    }

    const onEnd = (value, setValue) => {
        if (value === '') {
            setValue(0.00)
        }
    }

    return (
        <div className={styles.exchange}>
            <div className={`${styles.box} bgBlock`}>
                <div className={styles.box__header}>
                    <p>{pro.quanity}</p>
                    {currentTicker && currentTicker.token && (
                        <p>{currentTicker.token}</p>
                    )}
                </div>
                <div className={styles.box__value}>
                    <input type='number' inputmode="decimal" maxLength='12' value={value} onChange={(e) => handleChange(e.target.value)}
                           readOnly={!currentTicker} onFocus={() => onStart(value, setValue)} onBlur={() => onEnd(value, setValue)} />
                </div>
            </div>
            <div className={`${styles.box} bgBlock`}>
                <div className={styles.box__header}>
                    <p>{pro.total}</p>
                    {currentTicker && currentTicker.token2 && (
                        <p>{currentTicker.token2}</p>
                    )}
                </div>
                <div className={styles.box__value}>
                    <span>≈</span>
                    {/*<p>{value * coursePrice}</p>*/}
                    <input type='number' inputmode="decimal" maxLength='12' value={total} onChange={(e) => onTotalChange(e.target.value)}
                           onFocus={() => onStart(total, setTotal)} onBlur={() => onEnd(total, setTotal)} />
                </div>
            </div>
        </div>
    )
}

export default Exchange;